import React from 'react'
import { Link } from "gatsby"
import { useInView } from 'react-intersection-observer';

import { pageLink, fadeIn, ioOption } from '../../utils'

import './index.scss'


const Footer = () => {
  const [ refFooter, inViewFooter, entryFooter ] = useInView(ioOption);

  return (
    <footer className={`footer ${fadeIn(inViewFooter)}`}>
      <div ref={refFooter} className={`footer__container ${fadeIn(inViewFooter)}`}>
        <div className="footer-title">Navigate Include</div>
        <div className="footer__container__wrapper">
          <div className="footer-item developers">
            <h3>DEVELOPERS</h3>
            <nav>
              <Link to={ pageLink.demo }>Demos</Link>
            </nav>
          </div>
          <div className="footer-item community">
            <h3>COMMUNITY</h3>
            <nav className="community">
              {/* <a href={ pageLink.discord } target="_blank" rel="noreferrer" aria-label="Discord">Discord</a> */}
              <a href={ pageLink.discord } aria-label="Discord">Discord</a>
              <a href={ pageLink.twitter } target="_blank" rel="noreferrer" aria-label="Twitter">Twitter</a>
              {/* <a href={ pageLink.youtube } target="_blank" rel="noreferrer" aria-label="Youtube">Youtube</a> */}
              <a href={ pageLink.youtube } aria-label="Youtube">Youtube</a>
              {/* <a href="/" aria-label="Medium">Medium</a> */}
              {/* <a href="/" aria-label="Telegram">Telegram</a> */}
            </nav>
          </div>
          <div className="footer-item about">
            <h3>ABOUT</h3>
            <nav>
              {/* <Link to="/">Mission</Link> */}
              {/* <Link to="/">Values</Link> */}
              <a href={ pageLink.whitepaper } target="_blank" >Whitepaper</a>
              {/* <Link to="/">News</Link> */}
            </nav>
          </div>
          {/* <div className="footer-item other">
            <h3>OTHER</h3>
            <nav>
              <Link to="/">Tokenomics</Link>
              <Link to="/">Disclaimer</Link>
            </nav>
          </div> */}
        </div>
      </div>
    </footer>
  )
}

export default Footer
