import React from 'react'
import { Link } from 'gatsby'
import { useInView } from 'react-intersection-observer';


import { pageLink, fadeIn, ioOption } from '../../utils'

import RedditSvg from '../../assets/svg/reddit.svg';
import DiscordSvg from '../../assets/svg/discord.svg';
import TwitterSvg from '../../assets/svg/twitter.svg';
import CrossSvg from '../../assets/svg/close-cross.svg';
import MenuHamburgerSvg from '../../assets/svg/menu-hamburger.svg';
import IncludeLogoSvg from '../../assets/svg/include_logo.svg';


import './index.scss'


const TopNav = ({ 
  showMobileMenu,
  handleMenuButtonClick,
  handleCloseButtonClick
}) => {
  const [ refTopNav, inViewTopNav, entryTopNav ] = useInView(ioOption);
  
  return (
    <div ref={refTopNav}>
      <div className="top-nav">
        <div 
          className="top-nav__top-bar">
          <nav className={`top-nav__top-bar__inner ${fadeIn(inViewTopNav)}`}>
            <a className="desktop-link" href={ pageLink.whitepaper } target="_blank">WHITEPAPER</a>
            {/* <Link className="desktop-link" to={ pageLink.token }>INCL TOKEN</Link> */}
          </nav>
        </div>
        <div className="top-nav__nav-bar">
          <div className={`top-nav__nav-bar--left ${fadeIn(inViewTopNav)}`}>
            <Link className="logo" to={ pageLink.home }>
              <IncludeLogoSvg />
            </Link>
          </div>
          <div className={`top-nav__nav-bar--right ${fadeIn(inViewTopNav)}`}>
            {/* <Link className="desktop-link" activeClassName="active" to={ pageLink.developers }>DEVELOPERS</Link>
            <Link className="desktop-link" activeClassName="active" to={ pageLink.community }>COMMUNITY</Link>
            <Link className="desktop-link" activeClassName="active" to={ pageLink.about }>ABOUT</Link> */}

            <Link className="desktop-link" activeClassName="active" to={ pageLink.developers }>DEVELOPERS</Link>
            <Link className="desktop-link" activeClassName="active" to={ pageLink.community }>COMMUNITY</Link>
            <Link className="desktop-link" activeClassName="active" to={ pageLink.about }>ABOUT</Link>
            <div className="break"></div>
            <a className="icon-link twitter" href={ pageLink.twitter } target="_blank" rel="noreferrer" aria-label="Twitter"><TwitterSvg /></a>
            <a className="icon-link discord" href={ pageLink.discord } target="_blank" rel="noreferrer" aria-label="Discord"><DiscordSvg /></a>
            {/* <a className="icon-link reddit" href={ pageLink.reddit } aria-label="Reddit"><RedditSvg /></a> */}
          </div>
        </div>
      </div>
      <button 
        className={`top-nav--menu-hamburger ${fadeIn(inViewTopNav)}`} 
        onClick={handleMenuButtonClick}
        aria-label="menu open"
      >
        <MenuHamburgerSvg />
      </button>
      <Link className={`top-nav--mobile-logo ${fadeIn(inViewTopNav)}`} to={ pageLink.home }>
        <IncludeLogoSvg />
      </Link>
      <div className={`top-nav--mobile ${showMobileMenu ? "mobile-open" : ""}`}>
        <button 
          className="close-button" 
          onClick={handleCloseButtonClick}
          aria-label="menu close"
        >
          <CrossSvg />
        </button>
        <a className="top-nav--mobile__link--big-text" href={ pageLink.developers }>DEVELOPERS</a>
        <a className="top-nav--mobile__link--big-text" href={ pageLink.community }>COMMUNITY</a>
        <a className="top-nav--mobile__link--big-text" href={ pageLink.about }>ABOUT</a>
        {/* <a className="top-nav--mobile__link--small-text" href={ pageLink.token }>INCL TOKEN</a> */}
        <a className="top-nav--mobile__link--small-text" href={ pageLink.whitepaper }>WHITEPAPER</a>
        <div className="top-nav--mobile__link-container">
          <a className="icon-link twitter" href={ pageLink.twitter } aria-label="Twitter"><TwitterSvg className="twitter" /></a>
          <a className="icon-link discord" href={ pageLink.discord } aria-label="Discord"><DiscordSvg className="discord" /></a>
          {/* <a className="icon-link youtube" href={ pageLink.youtube } aria-label="Reddit"><RedditSvg className="reddit" /></a> */}
        </div>
      </div>
    </div>
  )
}

export default TopNav
