import React, { useState } from 'react'
import { Helmet } from "react-helmet"

// import FadeIn from 'react-fade-in';

import TopNav from '../TopNav'
import Footer from '../Footer'



import './index.scss'

const Layout = ({ title, children, pageClassName }) => {
  const [ showMobileMenu, setShowMobileMenu ] = useState( false );

  const handleMenuButtonClick = () => {
    setShowMobileMenu(true) 
  }

  const handleCloseButtonClick = () => {
    setShowMobileMenu(false)
  }
  return (
    <>
      <Helmet title={ title }>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
        <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&Montserrat:wght@300;500&display=swap" rel="stylesheet"/>      
      </Helmet>
      <TopNav 
        showMobileMenu={ showMobileMenu }
        handleMenuButtonClick={ handleMenuButtonClick }
        handleCloseButtonClick={ handleCloseButtonClick }
      />
      <main className={`${pageClassName} ${showMobileMenu ? "position-fixed" : ""} page`}>
        {children}
      </main>
      <Footer />
    </>
  )
}

export default Layout