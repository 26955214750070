export const pageLink = {
  home: "/",
  developers: "/developers",
  community: "/community",
  about: "/about",
  whitepaper: "https://docs.google.com/forms/d/1LECdOAD8p9IDrrxpZvRfL2UQlHKFIsPY4GvFQDjsZO0/edit",
  token: "/",
  docs: "/",
  github: "/",
  stackOverflow: "/",
  discord: "/",
  twitter: "https://twitter.com/IncludeDapp",
  youtube: "/",
  medium: "/",
  telegram: "/",
  tokenomics: "/",
  disclaimer: "/",
  // email: "mailto:abc@abc.com",
  email: "/",
  tedx: "https://www.tedxchaterroad.com/",
  contactForm: "https://docs.google.com/forms/d/1LECdOAD8p9IDrrxpZvRfL2UQlHKFIsPY4GvFQDjsZO0/edit",
  demo: "/"
}

export const contactFormLink = "https://docs.google.com/forms/d/1LECdOAD8p9IDrrxpZvRfL2UQlHKFIsPY4GvFQDjsZO0/edit"


export const demoLink = "kUZXOKr4EAc"

export const ioOption = {
  threshold: 0.2,
  triggerOnce: true,
  // rootMargin: '100px'
}

export const fadeIn = (InViewEl) => InViewEl ? "opacity-100" : "opacity-000"  
